* {
  box-sizing: border-box;
}
body {
  background-color: #d1d1d1;
  font-family: Arial;
}
:focus-visible {
  outline: none;
}
.container {
  width: 100%;
  max-width: 390px;
  margin: auto;
  height: 700px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
  overflow: hidden;
}
.header {
  width: 100%;
  height: 140px;
  background-image: url('https://images.unsplash.com/photo-1585409677983-0f6c41ca9c3b?ixlib=rb-1.2.1&w=1080&fit=max&q=80&fm=jpg&crop=entropy&cs=tinysrgb');
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  padding: 10px;
}

.header__box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr 14px 54px;
  align-items: center;
  row-gap: 2px;
  padding-inline: 10px;
}

.profile__name {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.profile__location{
  color: #ffffff;
  font-size: 0.75rem;
  text-align: center;
  grid-row: 2/3;
  grid-column: 1/4;
}
.profile__back {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.profile__search {
  grid-row: 3/4;
  grid-column: 1/4;
  margin: auto;
  width: 100%;
  border: none;
  height: 32px;
  border-radius: 4px;
  padding: 0px 10px;
}

.main {
  width: 100%;
  padding: 0px 10px;
}

.user__list {
  width: 95%;
  height: 560px;
  background-color: #ffffff;
  margin: auto;
  margin-top: -20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  row-gap: 10px;
  padding: 10px 20px;
}
.user__list hr {
  height: 0px;
  margin: 10px 0px 0px 0px;
  border: 0;
  border-bottom: 0.5px solid #e3e3e3;
}
.user__list__box {
  display: grid;
  column-gap: 12px;
  grid-template-columns: 80px auto 1fr;
}
.user__list__box__image {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.user__list__box__image > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.user__list__box__detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}
.user__list__box__contact {
  justify-self: end;
  align-self: center;
}

.user__list__box__detail--name {
  color: #434343;
  font-size: 0.875rem;
  font-weight: 600;
}
.user__list__box__detail--email {
  color: #878787;
  font-size: 0.75rem;
}
